<template>
  <div>
    <b-row v-if="p_patientData">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header header-bg-variant="white" class="p-1">
            <strong v-if="p_patientData.last_bsa_data && p_patientData.last_bsa_data.bsa_date">Son VYA Hesaplama</strong>
            <strong v-else>( Henüz VYA hesabı için gerekli olan kilo boy kaydı yok. Lütfen giriş yapınız. )</strong>
            <b-button size="sm" class="pull-right" @click="f_createNewBodySurfaceArea()" variant="primary"><i class="icon-plus"> Yeni kilo & boy</i></b-button>
          </b-card-header>
          <b-row v-if="p_patientData.last_bsa_data && p_patientData.last_bsa_data.bsa_date">
            <b-col cols="12">
              <b-row>
                <b-col cols="12">
                  <strong>Tarih:</strong>
                  <span v-if="p_patientData.last_bsa_data && p_patientData.last_bsa_data.bsa_date">
                    {{ f_dateTimeFormatWithDayDif(p_patientData.last_bsa_data.bsa_date) }}
                  </span>
                </b-col>
              </b-row>
              <template v-if="p_patientData.last_bsa_data && p_patientData.last_bsa_data.bsa_date">
                <b-row>
                  <b-col sm="4" md="4">
                    <strong>Kilo :</strong>
                    <span v-if="d_showKg"> {{p_patientData.last_bsa_data.weight_kg}} kg </span>
                    <span v-else> {{ Math.round(p_patientData.last_bsa_data.weight_kg / 0.45359237) }} lbs </span>
                  </b-col>
                  <b-col sm="4" md="4">
                    <strong>Boy :</strong>
                    <span v-if="d_showCm"> {{p_patientData.last_bsa_data.height_cm}} cm </span>
                    <span v-else> {{ (p_patientData.last_bsa_data.height_cm / 2.54).toFixed(1) }} inch </span>
                  </b-col>
                  <b-col sm="4" md="4">
                    <strong>VYA :</strong>
                    <span> {{ p_patientData.last_bsa_data.bsa }}  m² </span>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <modal v-if="d_showBsaModal" @close="d_showBsaModal = false" :p_width="'750'">
      <h3 slot="header">Kilo Boy Vya</h3>
      <div slot="body">
        <b-row class="form-group">
          <b-col cols="6">
            Tarih
          </b-col>
          <b-col cols="4">
            <b-form-input type="date" :max="d_maxDate" v-model="d_bsaData.bsa_date"></b-form-input>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-row>
        <b-row class="form-group" v-if="d_bsaSettings.weight_type === 'kg'">
          <b-col cols="6">
            Kilo (kg)
          </b-col>
          <b-col cols="4">
            <b-form-input type="number" min="0" v-model="d_bsaData.weight_kg"></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-radio v-model="d_bsaSettings.weight_type" name="kg" value="kg">kg</b-form-radio>
            <b-form-radio v-model="d_bsaSettings.weight_type" name="lbs" value="lbs">lbs</b-form-radio>
          </b-col>
        </b-row>
        <b-row class="form-group" v-if="d_bsaSettings.weight_type === 'lbs'">
          <b-col cols="6">
            Kilo (lbs)
          </b-col>
          <b-col cols="4">
            <b-form-input type="number" min="0" v-model="d_bsaData.weight_lbs"></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-radio v-model="d_bsaSettings.weight_type" name="kg" value="kg">kg</b-form-radio>
            <b-form-radio v-model="d_bsaSettings.weight_type" name="lbs" value="lbs">lbs</b-form-radio>
          </b-col>
        </b-row>
        <b-row class="form-group" v-if="d_bsaSettings.height_type === 'cm'">
          <b-col cols="6">
            Boy (cm)
          </b-col>
          <b-col cols="4">
            <b-form-input type="number" min="0" v-model="d_bsaData.height_cm"></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-radio v-model="d_bsaSettings.height_type" name="cm" value="cm">cm</b-form-radio>
            <b-form-radio v-model="d_bsaSettings.height_type" name="inch" value="inch">inch</b-form-radio>
          </b-col>
        </b-row>
        <b-row class="form-group" v-if="d_bsaSettings.height_type === 'inch'">
          <b-col cols="6">
            Boy (inch)
          </b-col>
          <b-col cols="4">
            <b-form-input type="number" min="0" v-model="d_bsaData.height_inch"></b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-radio v-model="d_bsaSettings.height_type" name="cm" value="cm">cm</b-form-radio>
            <b-form-radio v-model="d_bsaSettings.height_type" name="inch" value="inch">inch</b-form-radio>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="6">
            Maksimum vücut yüzey alanı 2 olabilir.
          </b-col>
          <b-col cols="4">
            <b-form-radio v-model="d_bsaData.max_vya_is_2" @change="f_bsaCalculate()" name="max_vya_is_2" :value="1">evet</b-form-radio>
            <b-form-radio v-model="d_bsaData.max_vya_is_2" @change="f_bsaCalculate()" name="max_vya_is_not_2" :value="0">hayır</b-form-radio>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="6">
            Vücut yüzey alanı
          </b-col>
          <b-col cols="4">
            {{ d_bsaData.bsa }}
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="6">
            Düzeltilmiş Kilo (kg)
          </b-col>
          <b-col cols="4">
            {{ d_bsaData.adjusted_weight_kg }}
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="6">
            Düzeltilmiş Kilo (lbs)
          </b-col>
          <b-col cols="4">
            {{ d_bsaData.adjusted_weight_lbs }}
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="6">
            İdeal kilo (kg)
          </b-col>
          <b-col cols="4">
            {{ d_bsaData.ideal_weight_kg }}
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="6">
            İdeal kilo (lbs)
          </b-col>
          <b-col cols="4">
            {{ d_bsaData.ideal_weight_lbs }}
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="6">
            Vücut kitle indeksi
          </b-col>
          <b-col cols="4">
            {{ d_bsaData.bmi }}
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveBodySurfaceArea()">Kaydet</button>
        <button type="button" class="btn btn-danger" @click="d_showBsaModal = false">Kapat</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as Modal
} from '@/components/widgets/Modal';
import { mapGetters } from 'vuex';
import { DateFunctions } from '@/public/DateFunctions';
import moment from 'moment';

export default {
  name: 'Bsa',
  computed: {
    ...mapGetters({
      StoreDevice: 'StoreDevice',
      StoreLoading: 'StoreLoading',
    })
  },
  components: {
    Modal,
  },
  props: {
    p_patientData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_bsaSettings: {
        'weight_type': 'kg',
        'height_type': 'cm'
      },
      d_bsaData: {
        'adjusted_weight_kg': '',
        'adjusted_weight_lbs': '',
        'bmi': '',
        'bsa': '',
        'bsa_date': '',
        'height_cm': '',
        'height_inch': '',
        'id': '',
        'ideal_weight_kg': '',
        'ideal_weight_lbs': '',
        'max_vya_is_2': 1,
        'weight_kg': '',
        'weight_lbs': '',
      },
      d_showBsaModal: false,
      d_showCm: true,
      d_showKg: true,
      d_user: {},
      d_maxDate: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {},
  methods: {
    f_saveBodySurfaceArea: function () {
      let day_dif = DateFunctions.day_dif(this.p_patientData.last_bsa_data.bsa_date, this.d_bsaData.bsa_date);
      if (day_dif >= 0) {
        this.p_patientData.last_bsa_data.adjusted_weight_kg = parseFloat(this.d_bsaData.adjusted_weight_kg);
        this.p_patientData.last_bsa_data.adjusted_weight_lbs = parseFloat(this.d_bsaData.adjusted_weight_lbs);
        this.p_patientData.last_bsa_data.bmi = parseFloat(this.d_bsaData.bmi);
        this.p_patientData.last_bsa_data.bsa = parseFloat(this.d_bsaData.bsa);
        this.p_patientData.last_bsa_data.bsa_date = this.d_bsaData.bsa_date;
        this.p_patientData.last_bsa_data.height_cm = parseFloat(this.d_bsaData.height_cm);
        this.p_patientData.last_bsa_data.height_inch = parseFloat(this.d_bsaData.height_inch);
        this.p_patientData.last_bsa_data.ideal_weight_kg = parseFloat(this.d_bsaData.ideal_weight_kg);
        this.p_patientData.last_bsa_data.ideal_weight_lbs = parseFloat(this.d_bsaData.ideal_weight_lbs);
        this.p_patientData.last_bsa_data.max_vya_is_2 = parseInt(this.d_bsaData.max_vya_is_2);
        this.p_patientData.last_bsa_data.weight_kg = parseFloat(this.d_bsaData.weight_kg);
        this.p_patientData.last_bsa_data.weight_lbs = parseFloat(this.d_bsaData.weight_lbs);
      }
      this.d_showBsaModal = false;
    },
    f_createNewBodySurfaceArea: function () {
      this.d_bsaData.adjusted_weight_kg = '';
      this.d_bsaData.adjusted_weight_lbs = '';
      this.d_bsaData.bmi = '';
      this.d_bsaData.bsa = '';
      this.d_bsaData.bsa_date = '';
      this.d_bsaData.height_cm = '';
      this.d_bsaData.height_inch = '';
      this.d_bsaData.id = '';
      this.d_bsaData.ideal_weight_kg = '';
      this.d_bsaData.ideal_weight_lbs = '';
      this.d_bsaData.max_vya_is_2 = 1;
      this.d_bsaData.weight_kg = '';
      this.d_bsaData.weight_lbs = '';
      if (this.p_patientData.last_bsa_data && this.p_patientData.last_bsa_data.bsa_date) {
        for (let i in this.p_patientData.last_bsa_data) {
          this.d_bsaData[i] = this.p_patientData.last_bsa_data[i];
        }
      }
      this.d_bsaData.bsa_date = moment(new Date().toISOString()).format('YYYY-MM-DD');
      this.d_showBsaModal = true;
    },
    f_dateTimeFormatWithDayDif: function (date) {
      let day_dif = DateFunctions.date_dif_today(date);
      if (day_dif === 0) {
        return moment(date).format('DD/MM/YYYY') + ' (bugün)';
      } else if (day_dif === 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün sonra)';
      } else if (day_dif > 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün sonra)';
      } else if (day_dif === -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün önce)';
      } else if (day_dif < -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün önce)';
      }
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_bsaCalculate: function () {
      if (this.d_bsaSettings.weight_type === 'lbs') {
        this.d_bsaData.weight_kg = Math.round(parseFloat(this.d_bsaData.weight_lbs) * 0.45359237);
      } else {
        this.d_bsaData.weight_lbs = Math.round(parseFloat(this.d_bsaData.weight_kg) / 0.45359237);
      }

      if (this.d_bsaSettings.height_type === 'inch') {
        this.d_bsaData.height_cm = Math.round(parseFloat(this.d_bsaData.height_inch) * 2.54);
      } else {
        this.d_bsaData.height_inch = (parseFloat(this.d_bsaData.height_cm) / 2.54).toFixed(2);
      }

      if (this.p_patientData.sex === '0') {
        let ideal_weight_kg = 50 + (2.3 * (this.d_bsaData.height_inch - 60));
        this.d_bsaData.ideal_weight_kg = parseFloat(ideal_weight_kg).toFixed(0);
        this.d_bsaData.ideal_weight_lbs = Math.round(parseFloat(this.d_bsaData.ideal_weight_kg) / 0.45359237);
      } else if (this.p_patientData.sex === '1') {
        let ideal_weight_kg = 45.5 + (2.3 * (this.d_bsaData.height_inch - 60));
        this.d_bsaData.ideal_weight_kg = parseFloat(ideal_weight_kg).toFixed(0);
        this.d_bsaData.ideal_weight_lbs = Math.round(parseFloat(this.d_bsaData.ideal_weight_kg) / 0.45359237);
      }
      if (this.d_bsaData.ideal_weight_kg) {
        let adjusted_weight = parseFloat(this.d_bsaData.ideal_weight_kg) + 0.4 * (this.d_bsaData.weight_kg - this.d_bsaData.ideal_weight_kg);
        this.d_bsaData.adjusted_weight_kg = parseFloat(adjusted_weight).toFixed(0);
        this.d_bsaData.adjusted_weight_lbs = Math.round(parseFloat(this.d_bsaData.adjusted_weight_kg) / 0.45359237);
      }
      this.d_bsaData.bmi = (this.d_bsaData.weight_kg / ((this.d_bsaData.height_cm / 100) * (this.d_bsaData.height_cm / 100))).toFixed(2);

      if (this.d_bsaData.height_cm && this.d_bsaData.weight_kg) {
        let bsa = 0.007184 * Math.pow(parseFloat(this.d_bsaData.height_cm), 0.725) * Math.pow(parseFloat(this.d_bsaData.weight_kg), 0.425);
        console.log('bsa : ', bsa);
        if (this.d_bsaData.max_vya_is_2 && bsa > 2) {
          this.d_bsaData.bsa = 2;
        } else {
          this.d_bsaData.bsa = parseFloat(bsa).toFixed(2);
        }
      }
    },
  },
  watch: {
    'd_bsaData.max_vya_is_2': function () {
      this.f_bsaCalculate();
    },
    'd_bsaData.height_cm': function () {
      this.f_bsaCalculate();
    },
    'd_bsaData.height_inch': function () {
      this.f_bsaCalculate();
    },
    'd_bsaData.weight_kg': function () {
      this.f_bsaCalculate();
    },
    'd_bsaData.weight_lbs': function () {
      this.f_bsaCalculate();
    }
  }
};

</script>

<style type="text/css">


</style>

