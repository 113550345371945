<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'bsapage'}"></work-subject>
        <hr>
        <h3>Vya Komponenti</h3>
        <bsa :p_patientData="d_patientData"></bsa>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.bsa_widget">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientBsa'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as Bsa
} from '@/components/widgets/Bsa';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import { mapGetters } from 'vuex';
export default {
  name: 'BsaPage',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    Bsa,
    SqlDatabase,
    WorkSubject
  },
  props: {},
  data () {
    return {
      d_componentExample: {
        'bsa_widget': [
          { 'code': '<bsa :p_patientData="d_patientData"></bsa>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
      d_patientData: require('@/example_data/patient_data').example_data,
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

